<template>
  <loginWithMagicLinkForm @request-magic-link="onRequestMagicLink" />

  <div class="relative inline-flex items-center justify-center w-full">
    <hr class="w-11/12 h-[1px] my-4 lg:my-8 bg-gray-300 border-0" />
    <div class="absolute px-4 -translate-x-1/2 bg-white left-1/2 text-grey">
      {{ $t("or") }}
    </div>
  </div>
  <div class="flex items-center justify-center pb-4">
    <NuxtLink to="/login/account" class="text-color-gradient font-medium">
      {{ $t("log_in_with_username_password") }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import loginWithMagicLinkForm from "~/components/auth/LoginMagicLinkForm.vue";

const router = useRouter();

const onRequestMagicLink = (email: string) => {
  router.push({
    path: `/login/magic-link/requested`,
    query: { email },
  });
};
</script>
