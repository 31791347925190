<template>
  <div class="px-4 lg:px-8 flex flex-col gap-4">
    <h3 class="font-bold">{{ $t("what_is_your_email_address") }}</h3>
    <p class="text-gray-500 text-sm">
      {{ $t("we_will_send_you_an_email_to_verify_identity") }}
    </p>
    <FwbCheckbox
      v-model="form.acceptTerms"
      :label="$t('create_account_accept')"
    />
    <NuxtLink
      to="/terms"
      target="_blank"
      class="underline text-send-purple-2 cursor-pointer"
    >
      {{ $t("terms_and_conditions.title") }}
    </NuxtLink>
    <div class="flex flex-col lg:flex-row w-full gap-2 justify-center">
      <input
        v-model="form.email"
        class="rounded border border-gray-600 flex-1 appearance-none w-full p-3 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
        name="email"
        type="email"
        placeholder="my@email.com"
        @keyup.enter.stop="!v.$invalid && loginWithMagicLink()"
      />
      <FwbButton
        color="dark"
        :disabled="v.$invalid"
        :loading="requestMagicLink.loading"
        @click="loginWithMagicLink"
      >
        {{ $t("send_link") }} ✨
      </FwbButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { email, required, sameAs } from "@vuelidate/validators";
import { useAuthStore } from "~/stores/auth";

import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import FwbCheckbox from "~/components/Flowbite/FwbCheckbox/FwbCheckbox.vue";

const emit = defineEmits(["request-magic-link"]);

const { requestMagicLink } = useAuthStore();

const form = reactive({
  email: null as null | undefined | string,
  sentToEmail: null as null | undefined | string,
  acceptTerms: false,
});

const v = useVuelidate(
  {
    email: {
      required,
      email,
    },
    acceptTerms: {
      required,
      sameAs: sameAs(true),
    },
  },
  form
);

const loginWithMagicLink = async () => {
  requestMagicLink.mutate({ email: form.email! });
};

requestMagicLink.onDone((result) => {
  emit("request-magic-link", result.data?.requestLoginLink.email);
});

requestMagicLink.onError((error) => {
  console.error(error);
});
</script>
